/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import { RxUtil } from "@/assets/util.js";
import { Radio, RadioGroup } from 'vue-ydui/dist/lib.rem/radio';
import RxSysuser from '@/components/common/SysUser';
import RxBoxs from '@/components/common/rx-boxs';
import router from '@/router/index';
export default {
  data() {
    return {
      mailUrge: "1",
      loginUser: {},
      showUserDialog: false,
      selectUserList: [],
      data: {
        receiver: "",
        content: "",
        subject: "",
        senderId: "",
        userId: "",
        recIds: ""
      },
      //选中的的rx-boxs实例，用于传递数据。
      nodeUserVm: {},
      needCopySend: false,
      backFlag: ""
    };
  },
  components: {
    RxSysuser,
    RxBoxs
  },
  methods: {
    getInfo() {
      var url = _baseUrl + '/mobile/oa/personalInfo/getUser.do';
      this.$ajax(url).then(response => {
        this.loginUser = response.data.user;
        this.data.senderId = this.loginUser.userId;
        this.data.userId = this.loginUser.userId;
      }).catch(error => {
        console.log(error);
      });
    },
    sendMsg() {
      let recIdsData = [];
      if (this.data.receiver != "") {
        recIdsData = JSON.parse(this.data.receiver);
      }
      if (recIdsData.length == 0) {
        this.errorToast("请选择收件人");
        return;
      }
      let recIdsArray = [];
      for (var i = 0; i < recIdsData.length; i++) {
        recIdsArray.push(recIdsData[i].id);
      }
      this.data.recIds = recIdsArray.join(",");

      /*String userId=request.getParameter("userId");
      String groupId=request.getParameter("groupId");
      String content = request.getParameter("context");//Msg内容
      String linkMsg = request.getParameter("linkMsg");//消息携带连接*/

      var url = _baseUrl + '/mobile/oa/sendMsg.do';
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          userId: this.data.recIds,
          content: this.data.content
        }
      }).then(response => {
        if (response.data.success) {
          this.successToast("发送消息成功", 1000);
          router.push({
            name: 'messageBox'
          });
          this.data.subject = '';
          this.data.content = '';
          this.$store.dispatch('getCountNum', this); //更新vuex数据
          /*this.$store.commit("changeCount",{
          	mes:'message',
          	vel:'+'
          });*/
        }
      }).catch(error => {
        console.log(error);
      });
    },
    /**
     * 打开用户对话框
     */
    selectUserDialog(vm) {
      var selectUser = vm.getIdVal(false);
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    /**
     * 点击用户对话框选择按钮，处理用户对话框返回数据。
     */
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    backToMsgBox() {
      router.push({
        name: 'messageBox'
      });
    }
  },
  created() {
    let reWriteMailData = this.$route.params.mailObject;
    if (reWriteMailData != null) {
      this.data = reWriteMailData;
    }
    this.getInfo();
  }
};